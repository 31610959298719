import styles from "./styles.module.css";

const LoUltimoVertical = (props) => {
  const { title, showDate } = props;
  
  const dateToHourMinutes = (date) => {
    const hour = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    return `${hour}:${minutes} -`;
  };

  return (
    <>
      {props.content && props.content.length > 0 && (
        <div className={`${styles["loultimo-parent"]}`}>
            <p className={`${styles["loultimo-title"]}`}>{title ? title : 'LO ÚLTIMO'}</p>
          <div className={`${styles["loultimo-container"]}`}>
            {props.content.map((element, id) => {
              const currentDate = new Date();
              const currentYear = parseInt(currentDate.getFullYear())
              const currentMonth = parseInt(currentDate.getMonth()) +1
              const currentDay = parseInt(currentDate.getDate())
              const { url, title, formattedPublishedDate, publishedDate } = element;

              let noteDate = new Date(publishedDate);
              let noteYear = noteDate.getFullYear();
              let noteMonth = noteDate.getMonth() + 1;
              let noteDay = noteDate.getDate();
              return (
                  <div key={element + id} className={`${styles["loultimo-container-text"]}`}>
                    { showDate
                      ? (<a href={url}>
                          {currentYear!=noteYear || currentMonth!=noteMonth || currentDay!=noteDay
                          ? <span className={`${styles["loultimo-hour"]}`}>
                              {`${formattedPublishedDate} -`}
                            </span>
                          : <span className={`${styles["loultimo-hour"]}`}>
                              {dateToHourMinutes(new Date(noteDate))}
                            </span>}
                          {` ${title}`}
                        </a>)
                      : <a href={url}>{title}</a>  
                  }
                  </div>
                )
              })
            }
          </div>
        </div>
        )}
    </>
  );
};

export default LoUltimoVertical;
